import { graphql, useStaticQuery } from 'gatsby'
import Parser from "html-react-parser"
import React, { useState } from 'react'
import BannerHeader from '../components/bannerHeader';
import BookDemo from '../components/book-demo';
import GetStartedModal from '../components/getStaredModal';
import bannerBgMobile from "../static/images/pryzm-banner-bg.webp"
import bannerBgDesktop from "../static/images/pryzm-Banner-desktop.webp"

import Underline from '../components/underline';
import { pryzmAIPageData } from '../services/helperFunctions';
import "../static/style/pageStyle/pryzmAI.scss"
import { Helmet } from 'react-helmet';

const PryzmAI = props => {
    const data = useStaticQuery(
        graphql`
          {
            allPryzmAi {
                nodes {
                  pryzmAI {
                    journey {
                        attributes {
                            cmpName
                            heading
                            subheading
                            imageUrl
                            paragraph {
                              text
                            }
                          }
                    }
                  }
                }
              }
          }
        `
    )

    const {
        banner,
        section1,
        section2,
        section3,
        section4,
    } = pryzmAIPageData(data);

    const [isOpen, setIsOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const handleIsOpenModal = (isModalOpen) => {
        setIsModalOpen(!isModalOpen);
        setIsOpen(!isOpen)
    }

    const metaTitle = "PRYZM AI | Your Copilot for Data Reliability";
    const metaDescription = "Transform your data processes with Pryzm’s GenAI solutions—automate asset documentation and accelerate the creation of comprehensive data quality rules."
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <div className={`platform-v3 pryzmAI`}>
                <div className='page-content'>
                    {/* //desktop */}
                    <div className='banner'>
                        {/* <img src={bannerBgDesktop} /> */}
                        <div className='header'>
                            <BannerHeader header={banner.heading} headingType="bannerTitle" line="single-line" />
                            <p>{banner.subheading}</p>
                        </div>
                    </div>

                    {/* mobile */}
                    {/* <div className='banner banner-mobile'>
                        <img src={bannerBgMobile} />
                        <div className='header'>
                            <BannerHeader header={banner.heading} headingType="bannerTitle" line="single-line" />
                            <p>{banner.subheading}</p>
                            <div className="book-demo">
                                <BookDemo
                                    content={"Book A Demo"}
                                    routeTo={"demo-page"}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className='section-01'>
                        <div className='page-wrapper'>
                            {/* <div className='heading'>
                                <BannerHeader header={section1.heading} line={"single-line"} />
                            </div> */}

                            <div className='paragraph'>
                                <p>{Parser(section1.subheading)}</p>
                            </div>
                            <div className="book-demo">
                                <BookDemo
                                    content={"Book A Demo"}
                                    routeTo={"demo-page"}
                                />
                            </div>
                        </div>
                    </div>

                    <Underline />

                    {/* //incident faster */}

                    {/* <div className='pryzm-general incident-faster'>
                        <div className='page-wrapper'>
                            <div className='content'>
                                <div className='header'>
                                    <h2>{section2.heading}</h2>
                                    <p>{section2.subheading}</p>
                                </div>
                                <div className='paragraph'>
                                    {section2.paragraph.map((item, index) => (
                                        <p>{Parser(item.text)}</p>
                                    ))}
                                </div>
                            </div>
                            <div className='image'>
                                <img src={`${process.env.STRAPI_URL + section2.imageUrl}`} />
                            </div>
                        </div>
                    </div> */}

                    {/* <Underline /> */}

                    {/* deeper insights */}
                    {/* <div className='pryzm-general'>
                        <div className='page-wrapper deeper-insight'>
                            <div className='image'>
                                <img src={`${process.env.STRAPI_URL + section3.imageUrl}`} />
                            </div>
                            <div className='content'>
                                <div className='header'>
                                    <h2>{section3.heading}</h2>
                                    <p>{section3.subheading}</p>
                                </div>
                                <div className='paragraph'>
                                    {section3.paragraph.map((item, index) => (
                                        <p>{Parser(item.text)}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <Underline /> */}

                    {/* auotdocument */}
                    <div className='pryzm-general autoDocument'>
                        <div className='page-wrapper'>
                            <div className='content'>
                                <div className='header'>
                                    <h2>{section2.heading}</h2>
                                    {/* <p>{section2.subheading}</p> */}
                                </div>
                                <div className='paragraph'>
                                    {section2.paragraph.map((item, index) => (
                                        <p>{Parser(item.text)}</p>
                                    ))}
                                </div>
                            </div>
                            <div className='image'>
                                <img src={`${process.env.STRAPI_URL + section2.imageUrl}`} />
                            </div>
                        </div>
                    </div>

                    <Underline />

                    {/* auto discovery */}
                    <div className='pryzm-general auto-discovery'>
                        <div className='page-wrapper'>
                            <div className='image'>
                                <img src={`${process.env.STRAPI_URL + section3.imageUrl}`} />
                            </div>
                            <div className='content'>
                                <div className='header'>
                                    <h2>{section3.heading}</h2>
                                    {/* <p>{section3.subheading}</p> */}
                                </div>
                                <div className='paragraph'>
                                    {section3.paragraph.map((item, index) => (
                                        <p>{Parser(item.text)}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* witness */}
                    <div className='witness'>
                        <div className='page-wrapper'>
                            <h2>{Parser(section4.heading)}</h2>
                            <div className="get-started-btn">
                                <GetStartedModal content={"Contact Us"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )

}
export default PryzmAI